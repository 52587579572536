import { Bars3BottomLeftIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";

interface IPageTitleProps {
    title: string;
    children?: React.ReactNode;
}

export function PageTitle({ title, children }: IPageTitleProps) {
    const [, setSidebarOpen] = useState<boolean>(false);

    return (
        <div className="sticky z-10 top-0 flex-shrink-0 h-24 border-b border-slate-200 flex bg-slate-50 mb-8">
            <button
                type="button"
                className="border-r border-slate-100 px-4 text-slate-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500 md:hidden"
                onClick={() => setSidebarOpen(true)}>
                <span className="sr-only">Open sidebar</span>
                <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="flex justify-between align-center items-center px-4 md:px-0 w-full">
                <h2 className="leading-7 text-gray-700 sm:text-2xl sm:truncate">{title}</h2>
                <div>{children}</div>
            </div>
        </div>
    );
}
