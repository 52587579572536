import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import classnames from "classnames";
import * as React from "react";
import { commonHooks } from "../common";

export interface IInputProps {
    [key: string]: any;
    prefixIcon?: string | React.ReactElement;
    suffixIcon?: React.ReactElement | string;
    prefixClassName?: string;
    type?: React.HTMLInputTypeAttribute;
    label?: string;
    name?: string;
    value?: string | undefined | null | number;
    defaultValue?: string | undefined | number;
    className?: string;
    placeholder?: string;
    readOnly?: boolean;
    required?: boolean;
    disabled?: boolean;
    autoComplete?: "off" | "current-password";
}

export const Input = React.forwardRef(
    (
        {
            label,
            name,
            prefixIcon,
            readOnly,
            suffixIcon,
            type,
            placeholder,
            className,
            prefixClassName,
            disabled,
            autoComplete = "current-password",
            ...restProps
        }: IInputProps,
        ref: React.ForwardedRef<HTMLInputElement>
    ) => {
        const [showPassword, setShowPassword] = React.useState<boolean>(false);
        const togglePassword = () => setShowPassword((prevState) => !prevState);

        const safeRef = commonHooks.useForwardedRef(ref);

        return (
            <div className={`rounded-md ${prefixIcon || suffixIcon ? "relative" : ""}`}>
                {prefixIcon && (
                    <div className={classnames("absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none", prefixClassName)}>
                        {prefixIcon}
                    </div>
                )}

                <input
                    {...restProps}
                    value={restProps.value === null ? "" : restProps?.value}
                    ref={safeRef}
                    id={name}
                    name={name}
                    disabled={disabled}
                    autoComplete={autoComplete}
                    readOnly={readOnly}
                    type={showPassword && type === "password" ? "text" : type}
                    placeholder={placeholder ?? label}
                    className={classnames(
                        "appearance-none block w-full p-2 border border-gray-300 text-gray-600 rounded-md placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm",
                        { "pl-10": prefixIcon },
                        className
                    )}
                />

                {!!suffixIcon && (
                    <div className="absolute inset-y-0 right-0 pr-3 text-gray-500 sm:text-sm flex items-center">{suffixIcon}</div>
                )}
                {type === "password" && (
                    <div className="absolute inset-y-0 right-0 pr-3 text-gray-500 sm:text-sm flex items-center">
                        {showPassword ? (
                            <EyeSlashIcon className="h-5 w-5 text-gray-400 cursor-pointer" onClick={togglePassword} />
                        ) : (
                            <EyeIcon className="h-5 w-5 text-gray-400 cursor-pointer" onClick={togglePassword} />
                        )}
                    </div>
                )}
            </div>
        );
    }
);
