import { authHooks } from "@app/auth";
import { branchUtils } from "@app/branch";
import { branchTypes } from "@app/branch/";
import { widgetSystemServerTypes, WidgetSystemServerSyncStateBadge } from "@app/widgetSystemServer";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { Badge } from "@ui";
import classNames from "classnames";
import { IBadgeVariant } from "@ui/badge/Badge";

interface IBranchHeaderProps {
    branch: branchTypes.IBranch;
    showAccountLevel: boolean;
    isActive: boolean;
    mainBranchId?: number;
    onClick: (property: branchTypes.IBranch) => void;
    onSyncClick?: (widgetSystemServerSyncResult: widgetSystemServerTypes.IWidgetSystemServerSyncResult) => void;
}

function BranchHeader({ branch, showAccountLevel, isActive, mainBranchId, onClick, onSyncClick }: IBranchHeaderProps): JSX.Element {
    const auth = authHooks.useAuth();
    const isAdmin = (auth.user?.roles || []).find((role) => role.name === "ADMIN") !== undefined;
    const isUserSwitched = auth.is_user_switched === true;
    const branchName = branchUtils.getBranchTitle(branch);
    const branchUrl = branchUtils.getBranchUrl(branch);

    return (
        <div className="flex w-full" key={branch.id}>
            <div
                className={classNames("border-r border-slate-200", {
                    "mx-10": showAccountLevel,
                    "ml-3.5 mr-8": !showAccountLevel,
                })}
            />
            <div
                key={branch.domain}
                onClick={() => onClick(branch)}
                className="my-2 p-3 bg-gray-50 hover:bg-blue-50 rounded cursor-pointer w-full">
                <div className="flex justify-between w-full items-center">
                    <div className="grow">
                        <span className={`flex items-center gap-x-2 text-sm font-medium text-gray-${isActive ? "900" : "600"}`}>
                            <span>{branchName}</span>
                            {mainBranchId === branch.id && (
                                <Badge size="xs" variant={IBadgeVariant.Warning}>
                                    main
                                </Badge>
                            )}

                            {(isAdmin || isUserSwitched) && (
                                <WidgetSystemServerSyncStateBadge
                                    onSyncClick={onSyncClick}
                                    widgetSystemServerSyncResult={branch.widgetSystemServerSyncResult}
                                    className=""></WidgetSystemServerSyncStateBadge>
                            )}
                        </span>
                        <p className="text-xs text-gray-500 text-clip overflow-hidden">{branchUrl}</p>
                    </div>
                    <div className="mx-8">{isActive && <Badge variant={IBadgeVariant.Primary}>Active</Badge>}</div>
                    <ChevronRightIcon className="w-5 h-5 text-gray-400" />
                </div>
            </div>
        </div>
    );
}

export { BranchHeader };
